import React, { useCallback, useRef } from 'react';
import {
  MdMailOutline,
  MdPersonOutline,
  MdLockOutline,
  MdChromeReaderMode,
  MdAssignmentInd,
  MdPhoneAndroid,
} from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router-dom';

import api from '../../services/api';
import Yup from '../../utils/validators/Yup';
import { getValidationErrors, getApiErrors } from '../../utils/getErrors';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import Button from '../../components/Button';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import avatarPlaceholderImg from '../../assets/avatar-placeholder.png';

import { Container, Content, AvatarContainer } from './styles';

interface ProfileFormData {
  name: string;
  email: string;
  identification_document: string;
  cellphone_number: string;
  job_title: string;
  old_password: string;
  password: string;
  password_confirmation: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          job_title: Yup.string().required('Cargo / Função é obrigatório'),
          cellphone_number: Yup.string().required('Celular é obrigatório'),
          identification_document: Yup.string()
            .isCPF('CPF inválido')
            .required('CPF é obrigatório'),
          // .transform((v, o) => (o === '' ? null : v))
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          old_password: Yup.string(),
          password: Yup.string().when('old_password', {
            is: val => !!val.length,
            then: Yup.string()
              .min(6, 'No mínimo 6 dígitos')
              .required('Campo obrigatório'),
            otherwise: Yup.string(),
          }),
          password_confirmation: Yup.string()
            .when('old_password', {
              is: val => !!val.length,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf(
              [Yup.ref('password'), undefined],
              'Confirmação de senha incorreta',
            ),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          name,
          email,
          identification_document,
          cellphone_number,
          job_title,
          old_password,
          password,
          password_confirmation,
        } = data;

        const formData = {
          name,
          email,
          identification_document,
          cellphone_number,
          job_title,
          ...(old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}),
        };

        const response = await api.put('/profile', formData);

        updateUser(response.data.user);

        navigate('/dashboard');

        addToast({
          type: 'success',
          title: 'Perfil atualizado!',
          description:
            'Suas informações do perfil foram atualizadas com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
          return;
        }

        // addToast({
        //   type: 'error',
        //   title: 'Erro na atualização',
        //   description:
        //     'Ocorreu um error ao atualizar o perfil, tente novamente.',
        // });
        addToast({
          type: 'error',
          title: 'Erro',
          delay: 10000,
          description: getApiErrors(error),
        });
      }
    },
    [addToast, navigate, updateUser],
  );

  // const handleAvatarChange = useCallback(
  //   (e: ChangeEvent<HTMLInputElement>) => {
  //     if (e.target.files) {
  //       const data = new FormData();

  //       data.append('avatar', e.target.files[0]);

  //       api.patch('/users/avatar', data).then(response => {
  //         updateUser(response.data);

  //         addToast({
  //           type: 'success',
  //           title: 'Avatar atualizado',
  //         });
  //       });
  //     }
  //   },
  //   [addToast, updateUser],
  // );

  // <label htmlFor="avatar">
  //             <FiCamera size={20} />
  //             <input
  //               data-testid="input-file"
  //               type="file"
  //               id="avatar"
  //               onChange={handleAvatarChange}
  //             />
  //           </label>

  return (
    <Container>
      <Content>
        <Form ref={formRef} initialData={user} onSubmit={handleSubmit}>
          <AvatarContainer>
            <img src={avatarPlaceholderImg} alt={user.name} />
          </AvatarContainer>

          <h1>Meu Perfil</h1>

          <Input name="name" icon={MdPersonOutline} placeholder="Nome" />
          <InputMask
            name="identification_document"
            icon={MdChromeReaderMode}
            placeholder="CPF"
            mask="999.999.999-99"
          />
          <Input
            name="job_title"
            icon={MdAssignmentInd}
            placeholder="Cargo / Função"
          />

          <InputMask
            name="cellphone_number"
            icon={MdPhoneAndroid}
            placeholder="Celular"
            mask="(99) 99999-9999"
          />

          <Input name="email" icon={MdMailOutline} placeholder="E-mail" />

          <Input
            containerStyle={{ marginTop: 24 }}
            name="old_password"
            icon={MdLockOutline}
            type="password"
            placeholder="Senha atual"
          />
          <Input
            name="password"
            icon={MdLockOutline}
            type="password"
            placeholder="Nova senha"
          />
          <Input
            name="password_confirmation"
            icon={MdLockOutline}
            type="password"
            placeholder="Confirmar senha"
          />

          <Button type="submit">Salvar Alterações</Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
