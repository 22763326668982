import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Toolbar, makeStyles } from '@material-ui/core';

import Logo from '../../Logo';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const TopBar: React.FC = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} elevation={0}>
      <Toolbar>
        <RouterLink to="/">
          <Logo height={80} />
        </RouterLink>

        <Box flexGrow={1} />
      </Toolbar>
    </AppBar>
  );
};

export default memo(TopBar);
