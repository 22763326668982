import { makeStyles } from '@material-ui/core';

const STEP_PADDING = 20;
export const useStyles = makeStyles(theme => ({
  row: {
    fontSize: 14,
    cursor: 'pointer',
    padding: '2px 0',
    display: 'flex',
    alignItems: 'flex-end',
    color: '#444',
  },
  'level-0': {
    paddingLeft: 0,
  },
  'level-1': {
    paddingLeft: STEP_PADDING,
  },
  'level-2': {
    paddingLeft: STEP_PADDING * 2,
  },
  'level-3': {
    paddingLeft: STEP_PADDING * 3,
  },
  'level-4': {
    paddingLeft: STEP_PADDING * 4,
  },
  'level-5': {
    paddingLeft: STEP_PADDING * 5,
  },
  'level-6': {
    paddingLeft: STEP_PADDING * 6,
  },
  'level-7': {
    paddingLeft: STEP_PADDING * 7,
  },
  text: {
    paddingLeft: 5,
    fontFamily: theme.typography.fontFamily,
  },
  folder: {
    color: '#2185d0',
  },
  folderOpen: {
    color: '#2185d0',
  },
  file: {
    marginLeft: 21,
    color: '#222',
  },
  chevron: {
    transition: 'transform 0.1s linear !important',
  },

  children: {
    overflow: 'hidden',
    transition: 'height 0.2s ease-in',
  },
  collapsed: {
    height: 0,
  },
  rotated: {
    transform: 'rotate(90deg)',
  },
}));
