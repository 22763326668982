import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function isTokenExpiredError(err: any): boolean {
  const status = err.response ? err.response.status : null;
  if (status === 403) {
    // err.response.data.code === 'E_JWT_TOKEN_EXPIRED'
    return true;
  }
  return false;
}

// axios interceptor for expired JWT refresh
export const apiRegisterIntercept = (): void => {
  api.interceptors.response.use(
    response => response,
    err => {
      if (isTokenExpiredError(err)) {
        try {
          localStorage.removeItem('@ISOPOINT:token');
          localStorage.removeItem('@ISOPOINT:user');
          api.defaults.headers.authorization = '';
          window.location.href = window.location.origin;
          // const { protocol, hostname } = window.location;
        } catch (errorRefresh) {
          // error while refreshing logout user and return original request error
          return Promise.reject(err);
        }
      }
      return Promise.reject(err);
    },
  );
};

export default api;
