import React from 'react';
import clsx from 'clsx';
import { ListItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
  },
}));

interface NavLinkProps {
  className?: string;
  href: string;
  icon: React.ElementType;
  title: string;
  component?: string;
  rel?: string;
  target?: string;
}

const NavLink: React.FC<NavLinkProps> = ({
  className,
  href,
  icon: Icon,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <ListItem
        className={clsx(classes.button, className)}
        disableGutters
        button
        component="a"
        href={href}
        rel="noreferrer noopener"
        target="_blank"
        {...rest}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </ListItem>
    </div>
  );
};

export default NavLink;
