/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import Folder from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import DescriptionIcon from '@material-ui/icons/Description';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';

import { useStyles } from './styles';

export type ItemNode = {
  id: string;
  parentId: string | null;
  name: string;
  description: string;
  mimeType: string;
  downloadUrl: string | null;
  isDirectory: boolean;
  hasChildren: boolean;
  children: ItemNode[];
};

type TreeNodeProps = {
  item: ItemNode;
  level?: number;
  children?: React.ReactNode;
  handleClick: (fileId: string, fileName: string) => void;
};

export const TreeNode: React.FC<TreeNodeProps> = ({
  item,
  level = 0,
  handleClick,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const styles = useStyles();

  return (
    <>
      {item.isDirectory ? (
        <>
          <div
            className={clsx(
              styles.row,
              styles[`level-${level}` as keyof typeof styles],
            )}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {!item.hasChildren ? null : (
              <ChevronRightIcon
                className={clsx(styles.chevron, {
                  [styles.rotated]: !isCollapsed,
                })}
              />
            )}

            {isCollapsed ? (
              <Folder className={styles.folder} />
            ) : (
              <FolderOpenIcon className={styles.folderOpen} />
            )}

            <span className={styles.text}>{item.name}</span>
          </div>
          <div
            className={clsx(styles.children, {
              [styles.collapsed]: isCollapsed,
            })}
          >
            {item.children.length
              ? item.children
                  .sort(nodeChild => (nodeChild.isDirectory ? -1 : 1))
                  .map(nodeChild => (
                    <TreeNode
                      key={`item-${uuidv4()}`}
                      handleClick={handleClick}
                      item={nodeChild}
                      level={level + 1}
                    />
                  ))
              : null}
          </div>
        </>
      ) : (
        <>
          <div
            className={clsx(
              styles.row,
              styles[`level-${level}` as keyof typeof styles],
            )}
            onClick={() => handleClick(item.id, item.name)}
          >
            {item.mimeType.includes('pdf') ? (
              <PictureAsPdf className={styles.file} />
            ) : (
              <DescriptionIcon className={styles.file} />
            )}

            <span className={styles.text}>{item.name.toUpperCase()}</span>
          </div>
        </>
      )}
    </>
  );
};
