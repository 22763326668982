import React, { useCallback, useRef } from 'react';
import {
  MdArrowBack,
  MdMailOutline,
  MdPersonOutline,
  MdLockOutline,
  MdPhoneAndroid,
  MdAssignmentInd,
  MdChromeReaderMode,
  MdReceipt,
  MdBusiness,
} from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useNavigate } from 'react-router-dom';

import api from '../../services/api';
import Yup from '../../utils/validators/Yup';
import { getValidationErrors, getApiErrors } from '../../utils/getErrors';
import { useToast } from '../../hooks/toast';

import Input from '../../components/Input';
import Separator from '../../components/Separator';
import InputMask from '../../components/InputMask';
import Button from '../../components/Button';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  FormSection,
} from './styles';

import logoImg from '../../assets/logo.svg';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          job_title: Yup.string().required('Cargo / Função é obrigatório'),
          cellphone_number: Yup.string().required('Celular é obrigatório'),
          identification_document: Yup.string()
            .isCPF('CPF inválido')
            .required('CPF é obrigatório'),
          // .transform((v, o) => (o === '' ? null : v))
          email: Yup.string()
            .required('E-mail é obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
          company: Yup.object().shape({
            name: Yup.string().required('Razão Social é obrigatório'),
            identification_code: Yup.string()
              .isCNPJ('CNPJ inválido')
              .required('CPF é obrigatório'),
          }),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('/accounts', data);

        navigate('/');

        addToast({
          type: 'success',
          title: 'Cadastro realizado!',
          description: 'Você já pode fazer seu logon no ISOPOINT!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          delay: 10000,
          description: getApiErrors(error),
        });
      }
    },
    [addToast, navigate],
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="ISOPOINT" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu cadastro</h1>
            <Input name="name" icon={MdPersonOutline} placeholder="Nome" />
            <InputMask
              name="identification_document"
              icon={MdChromeReaderMode}
              placeholder="CPF"
              mask="999.999.999-99"
            />
            <Input
              name="job_title"
              icon={MdAssignmentInd}
              placeholder="Cargo / Função"
            />

            <InputMask
              name="cellphone_number"
              icon={MdPhoneAndroid}
              placeholder="Celular"
              mask="(99) 99999-9999"
            />

            <Input name="email" icon={MdMailOutline} placeholder="E-mail" />

            <Input
              name="password"
              icon={MdLockOutline}
              type="password"
              placeholder="Senha"
            />

            <FormSection>
              <Separator />

              <h3>Dados da Empresa</h3>
              <InputMask
                name="company.identification_code"
                icon={MdReceipt}
                placeholder="CNPJ"
                mask="99.999.999/9999-99"
              />

              <Input
                name="company.name"
                icon={MdBusiness}
                placeholder="Razão Social"
              />
            </FormSection>
            <Button type="submit">Cadastrar</Button>
          </Form>

          <Link to="/">
            <MdArrowBack />
            Voltar para logon
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
