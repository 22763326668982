import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

// interface PrivateRouteProps {
//   element?: React.ReactElement;
// }
// https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6

const PrivateRoute: React.FC = ({ children, ...rest }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/signin" />;
  }

  return (
    <Route element={<Outlet />} {...rest}>
      {children}
    </Route>
  );
};

export default PrivateRoute;
