import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { apiRegisterIntercept } from '../services/api';
import DashboardLayout from '../components/Layouts/DashboardLayout';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Profile from '../pages/Profile';
import { Documents } from '../pages/Documents';
import CertificateList from '../pages/Certificates/List';
import CertificateShow from '../pages/Certificates/Show';
import CertificateShowLegacy from '../pages/Certificates/ShowLegacy';

apiRegisterIntercept();

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="signin" element={<SignIn />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route
        path="certificados/exibir/:certificateHashId"
        element={<CertificateShowLegacy />}
      />
      <Route
        path="acreditacoes/exibir/:certificateHashId"
        element={<CertificateShowLegacy />}
      />

      <PrivateRoute>
        <DashboardLayout>
          <Route path="/" element={<Dashboard />} />
          {/* <Route path="certificates/*" element={<CertificateList />}> */}
          <Route path="certificates" element={<CertificateList />} />
          <Route path="documents" element={<Documents />} />
          <Route
            path="certificates/show/:certificateHashId"
            element={<CertificateShow />}
          />
          {/* </Route> */}

          <Route path="/profile" element={<Profile />} />

          <Route path="*" element={<Navigate to="/" />} />
        </DashboardLayout>
      </PrivateRoute>

      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  );
};

export default AppRoutes;
