import React from 'react';

import logo from '../../assets/logo-white.svg';

interface LogoProps {
  height?: number;
}

const Logo: React.FC<LogoProps> = ({ height = 42, ...rest }) => {
  return <img alt="Logo" height={height} src={logo} {...rest} />;
};

export default Logo;
