import React, { useState, useCallback, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress,
} from '@material-ui/core';

import { DirectoryTree } from '../../components/DirectoryTree';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import Page from '../../components/Layouts/Page';

import { useStyles } from './styles';

export type ItemNode = {
  id: string;
  parentId: string | null;
  name: string;
  description: string;
  mimeType: string;
  downloadUrl: string | null;
  isDirectory: boolean;
  hasChildren: boolean;
  children: ItemNode[];
};

export const Documents: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState<ItemNode[]>([]);

  const { addToast } = useToast();
  const classes = useStyles();

  async function handleDownload(
    fileId: string,
    fileName: string,
  ): Promise<void> {
    try {
      const downloadResponse = await api.get(
        `/documents/drive-download/${fileId}`,
        { responseType: 'blob' },
      );

      const url = window.URL.createObjectURL(downloadResponse.data);
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.download = `${fileName}`;
      a.click();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Não foi possível acessar o documento solicitado',
        // description: getApiErrors(err),
      });
    }
  }

  const loadCertificates = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await api.get<{ documents: ItemNode[] }>(
        '/documents/drive-list',
      );

      if (response.data) {
        setDocuments(response.data.documents);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar os documentos',
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadCertificates();
  }, []);

  return (
    <>
      <Page className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} xs={12}>
              <Card>
                <CardHeader title="Documentos da Qualidade" />

                <Divider />

                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      {!isLoading ? (
                        <DirectoryTree
                          nodes={documents}
                          handleClick={handleDownload}
                        />
                      ) : (
                        <CircularProgress color="primary" size={100} />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default Documents;
