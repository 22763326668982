import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { TreeNode } from './TreeNode';

export type ItemNode = {
  id: string;
  parentId: string | null;
  name: string;
  description: string;
  mimeType: string;
  downloadUrl: string | null;
  isDirectory: boolean;
  hasChildren: boolean;
  children: ItemNode[];
};

type DirectoryTreeProps = {
  nodes: ItemNode[];
  handleClick: (fileId: string, fileName: string) => void;
};

export const DirectoryTree: React.FC<DirectoryTreeProps> = ({
  nodes,
  handleClick,
}) => {
  return (
    <>
      {nodes.map(node => (
        <TreeNode
          key={`item-${uuidv4()}`}
          handleClick={handleClick}
          item={node}
        />
      ))}
    </>
  );
};
