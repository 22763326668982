import React, { useCallback, useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
  colors,
  makeStyles,
} from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import TopBarUnauthenticated from '../../../components/Layouts/TopBarUnauthenticated';
import Page from '../../../components/Layouts/Page';
import Label from '../../../components/Label';

interface ICompany {
  id: number;
  name: string;
  identification_code: string;
  formated_identification_code: string;
}

interface ICertificateType {
  id: number;
  short_type: string;
  type: string;
}

interface ICertificationCategory {
  id: number;
  category: string;
}

interface ICertificateSituation {
  id: number;
  situation: string;
}

interface ICertificate {
  id: number;
  hash_id: string;
  company_id: number;
  certificate_type_id: number;
  contract_year: number;
  revision_number: number;
  scope: string;
  normatives: string;
  certification_category_id: number;
  first_concession: Date;
  validity_start: Date;
  validity_end: Date;
  certificate_situation_id: number;
  is_published: boolean;
  certificate_number_old: string;
  certificate_file: string;
  created_at: Date;
  updated_at: Date;
  formated_certificate_code: string;
  formated_first_concession: string;
  formated_validity_start: string;
  formated_validity_end: string;

  company: ICompany;
  certificate_type: ICertificateType;
  certification_category: ICertificationCategory;
  certificate_situation: ICertificateSituation;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(16),
  },
  cardQr: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableLineTitle: {
    fontWeight: 'bold',
  },
}));

const CertificateShowLegacy: React.FC = () => {
  const { addToast } = useToast();
  const { certificateHashId } = useParams();
  const { pathname } = useLocation();
  const classes = useStyles();

  const [loadingCertificate, setLoadingCertificate] = useState<boolean>(true);
  const [certificate, setCertificate] = useState<ICertificate | null>(null);

  const setLabelColor = (id: number): string => {
    switch (id) {
      case 1:
        return colors.green[600];
      case 2:
        return colors.red[600];
      case 3:
        return colors.orange[600];
      default:
        return colors.red[900];
    }
  };

  const loadCertificate = useCallback(async () => {
    setLoadingCertificate(true);

    try {
      const certificate_type_id =
        pathname.split('/')[1] === 'acreditacoes' ? 2 : undefined;

      const response = await api.get(`/certificates/${certificateHashId}`, {
        params: {
          certificate_type_id,
        },
      });

      setCertificate(response.data?.certificate);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Código do Certificado Inválido',
      });
    }

    setLoadingCertificate(false);
  }, [certificateHashId, addToast, pathname]);

  useEffect(() => {
    loadCertificate();
  }, [loadCertificate]);

  return (
    <>
      <TopBarUnauthenticated />

      <Page className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item lg={8} md={8} xs={12}>
              <Card>
                <CardHeader
                  subheader="Detalhes das Informações"
                  title="Certificado"
                />

                <Divider />

                {!loadingCertificate && certificate ? (
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item md={12} xs={12}>
                        <TableContainer>
                          <Table aria-label="sticky table">
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Empresa
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {certificate?.company.name} -{' '}
                                  {
                                    certificate?.company
                                      .formated_identification_code
                                  }
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Nº Certificado
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {certificate?.formated_certificate_code}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Identificador
                                  </Typography>
                                </TableCell>
                                <TableCell>{certificate?.hash_id}</TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Tipo do Certificado
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {`${certificate?.certificate_type.type} - (${certificate?.certificate_type.short_type})`}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Categoria da Certificação
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {certificate?.certification_category.category}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Ano do Contrato
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {certificate?.contract_year}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Revisão
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {certificate?.revision_number}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Escopo
                                  </Typography>
                                </TableCell>
                                <TableCell>{certificate?.scope}</TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Normativas
                                  </Typography>
                                </TableCell>
                                <TableCell>{certificate?.normatives}</TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Primeira Concessão
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {certificate?.formated_first_concession}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Período de Validade
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {certificate?.formated_validity_start
                                    ? `${certificate?.formated_validity_start} até\n ${certificate?.formated_validity_end}`
                                    : '-'}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    className={classes.tableLineTitle}
                                  >
                                    Situação
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Label
                                    color={setLabelColor(
                                      certificate?.certificate_situation_id,
                                    )}
                                  >
                                    {certificate?.certificate_situation
                                      ?.situation ?? '-'}
                                  </Label>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                ) : (
                  <CircularProgress color="primary" size={100} />
                )}
              </Card>
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <Card>
                <CardHeader title="QRCODE" />

                <Divider />
                {!loadingCertificate && certificate ? (
                  <CardContent className={classes.cardQr}>
                    <QRCode
                      value={
                        certificate?.hash_id &&
                        `${process.env.REACT_APP_WEB_URL}/certificados/exibir/${certificate?.hash_id}`
                      }
                      size={256}
                      bgColor="#ffffff"
                      fgColor="#000000"
                      level="H"
                      includeMargin={false}
                      renderAs="canvas"
                    />
                  </CardContent>
                ) : (
                  <CircularProgress color="primary" size={100} />
                )}
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default CertificateShowLegacy;
