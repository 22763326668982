import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  CircularProgress,
  makeStyles,
  colors,
} from '@material-ui/core';
import {
  DescriptionOutlined as ShowIcon,
  PictureAsPdf as DownloadIcon,
} from '@material-ui/icons';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';

import Page from '../../../components/Layouts/Page';
import Toolbar from './Toolbar';
import Label from '../../../components/Label';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  rootCard: {
    backgroundColor: theme.palette.background.default,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  notPublished: {
    background: '#ffc4c4',
  },
  tableContainer: {
    // maxHeight: 490,
    maxHeight: '57vh',
    // maxWidth: 'calc(100vw -900px)',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

interface ICompany {
  id: number;
  name: string;
  identification_code: string;
  formated_identification_code: string;
}

interface ICertificateType {
  id: number;
  short_type: string;
  type: string;
}

interface ICertificationCategory {
  id: number;
  category: string;
}

interface ICertificateSituation {
  id: number;
  situation: string;
}

interface ICertificate {
  id: number;
  hash_id: string;
  company_id: number;
  certificate_type_id: number;
  contract_year: number;
  revision_number: number;
  scope: string;
  normatives: string;
  certification_category_id: number;
  first_concession: Date;
  validity_start: Date;
  validity_end: Date;
  certificate_situation_id: number;
  is_published: boolean;
  certificate_number_old: string;
  certificate_file: string;
  created_at: Date;
  updated_at: Date;
  formated_certificate_code: string;
  formated_first_concession: string;
  formated_validity_start: string;
  formated_validity_end: string;

  company: ICompany;
  certificate_type: ICertificateType;
  certification_category: ICertificationCategory;
  certificate_situation: ICertificateSituation;
}

const CertificateList: React.FC = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const classes = useStyles();

  const typingDebounceRef = useRef<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [certificates, setCertfificates] = useState<ICertificate[]>([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(50);
  const [loading, setLoading] = useState(true);

  const setLabelColor = (id: number): string => {
    switch (id) {
      case 1:
        return colors.green[600];
      case 2:
        return colors.red[600];
      case 3:
        return colors.orange[600];
      default:
        return colors.red[900];
    }
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage + 1);
  };

  const handleChangeResultsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setResultsPerPage(+event.target.value);
    setPage(1);
  };

  async function handleDownload(hash_id: string): Promise<void> {
    try {
      const downloadResponse = await api.get(
        `/certificates/download/${hash_id}`,
        { responseType: 'blob' },
      );

      const url = window.URL.createObjectURL(downloadResponse.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `certificado_${hash_id}.pdf`;
      a.click();
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Não foi possível acessar o certificado solicitado',
        // description: getApiErrors(err),
      });
    }
  }

  const handleChangeSearchTerm = (newSearchTerm: string): void => {
    setSearchTerm(newSearchTerm);
  };

  const loadCertificates = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get('/certificates', {
        params: {
          searchTerm,
          page,
          resultsPerPage,
        },
      });

      setCertfificates(response.data.certificates);
      setTotalRecords(response.data.totalRecords);
      setLoading(false);
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar os certificados',
      });
    }
  }, [searchTerm, page, resultsPerPage, addToast]);

  // search while typing
  useEffect(() => {
    if (typingDebounceRef.current) {
      clearTimeout(typingDebounceRef.current);
    }

    typingDebounceRef.current = setTimeout(() => {
      loadCertificates();
    }, 600);
  }, [searchTerm, page, resultsPerPage,]); // eslint-disable-line

  // useEffect(() => {
  //   loadCertificates();
  // }, []); // eslint-disable-line

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar onSearch={handleChangeSearchTerm} />
        <Box mt={3}>
          <Card className={classes.rootCard}>
            {/* <CardHeader title="Certificados" /> */}
            <Box className={classes.tableWrapper}>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ minWidth: 200 }}>
                        Ações
                      </TableCell>

                      <TableCell align="left">Empresa</TableCell>

                      <TableCell align="left">Nº Certificado</TableCell>

                      <TableCell align="left">Revisão</TableCell>

                      <TableCell align="left">Período de Validade</TableCell>

                      <TableCell align="center">Situação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading ? (
                      certificates.map(certificate => {
                        return (
                          <TableRow
                            hover
                            key={certificate.id}
                            className={
                              certificate.is_published
                                ? 'published'
                                : classes.notPublished
                            }
                          >
                            <TableCell align="center">
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  navigate(
                                    `/certificates/show/${certificate.hash_id}`,
                                  )
                                }
                              >
                                <ShowIcon />
                              </IconButton>

                              <IconButton
                                color="primary"
                                disabled={!certificate.certificate_file}
                                onClick={() =>
                                  handleDownload(certificate.hash_id)
                                }
                              >
                                <DownloadIcon />
                              </IconButton>
                            </TableCell>

                            <TableCell>{`${certificate.company.name} - (${certificate.company.identification_code})`}</TableCell>

                            <TableCell>
                              {certificate.formated_certificate_code}
                            </TableCell>

                            <TableCell>{certificate.revision_number}</TableCell>

                            <TableCell>
                              {certificate.formated_validity_start
                                ? `${certificate.formated_validity_start} até\n ${certificate.formated_validity_end}`
                                : '-'}
                            </TableCell>

                            <TableCell align="center">
                              <Label
                                color={setLabelColor(
                                  certificate.certificate_situation_id,
                                )}
                              >
                                {certificate?.certificate_situation
                                  ?.situation ?? '-'}
                              </Label>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>
                          <CircularProgress color="primary" size={100} />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <TablePagination
              rowsPerPageOptions={[50, 100, 150, 200]}
              component="div"
              count={totalRecords}
              rowsPerPage={resultsPerPage}
              page={page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeResultsPerPage}
            />
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default CertificateList;
