import React, { useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles,
} from '@material-ui/core';
import {
  ExpandMore as ArrowDownIcon,
  DescriptionOutlined as DescriptionIcon,
} from '@material-ui/icons';

import Page from '../../components/Layouts/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F4F6F8',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  pannelsWrapper: {
    width: '100%',
    minWidth: 650,
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    flexBasis: '70%',
    flexShrink: 0,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const categoriesForms = [
  {
    pannelName: 'panel1',
    title: 'PRODUTOS',
    itens: [
      {
        name: 'Certificação Modelo 2 e 3',
        url: 'https://app.pipefy.com/public/form/dHG9Wnqv',
      },
      {
        name: 'Certificação Modelo 5',
        url: 'https://app.pipefy.com/public/form/qoxVFJSW',
      },
    ],
  },
  {
    pannelName: 'panel2',
    title: 'OPERADORAS DE PLANO DE SAÚDE - OPS',
    itens: [
      {
        name: 'OPS Acreditação',
        url: 'https://app.pipefy.com/public/form/XOoaCYGD',
      },
      {
        name: 'OPS Diagnóstico para Acreditação',
        url: 'https://app.pipefy.com/public/form/nhNYr-Mc',
      },
    ],
  },
  {
    pannelName: 'panel3',
    title: 'RÓTULO AMBIENTAL - ECOSELO',
    itens: [
      {
        name: 'Rótulo Ambiental - Tipo I',
        url: 'https://app.pipefy.com/public/form/DCOi3_lP',
      },
    ],
  },
  {
    pannelName: 'panel4',
    title: 'SERVIÇOS',
    itens: [
      {
        name: 'Certificação Modelo 6',
        url: 'https://app.pipefy.com/public/form/7QShlljt',
      },
    ],
  },
  {
    pannelName: 'panel5',
    title: 'AVALIAÇÃO DA CONFORMIDADE',
    itens: [
      {
        name: 'Avaliação da Conformidade',
        url: 'https://app.pipefy.com/public/form/AgBA6kXn',
      },
    ],
  },
  {
    pannelName: 'panel6',
    title: 'EXTENSÕES',
    itens: [
      {
        name: 'Extensões',
        url: 'https://app.pipefy.com/public/form/qU7GfUob',
      },
    ],
  },
  {
    pannelName: 'panel7',
    title: 'ADEQUAÇÃO DO PROCESSO',
    itens: [
      {
        name: 'Adequação do Processo',
        url: 'https://app.pipefy.com/public/form/3JTjvTqY',
      },
    ],
  },
  {
    pannelName: 'panel8',
    title: 'MUDANÇA NO PROCESSO',
    itens: [
      {
        name: 'Mudança no Processo',
        url: 'https://app.pipefy.com/public/form/Q9LVhvbI',
      },
    ],
  },
  {
    pannelName: 'panel9',
    title: 'SUSPENSÃO OU CANCELAMENTO DE PROCESSO',
    itens: [
      {
        name: 'Suspensão ou Cancelamento de Processo',
        url: 'https://app.pipefy.com/public/form/zIIaCoCU',
      },
    ],
  },
  {
    pannelName: 'panel10',
    title: 'TRANSFERÊNCIA DE CERTIFICAÇÃO',
    itens: [
      {
        name: 'Transferência de Certificação',
        url: 'https://app.pipefy.com/public/form/A5ntL0-Q',
      },
    ],
  },
];

const Dashboard: React.FC = () => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <CardHeader
                title="Solicitações"
                subheader="Selecione uma Opção"
              />

              <Divider />

              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <div className={classes.pannelsWrapper}>
                      {categoriesForms.map(category => (
                        <Accordion
                          key={category.pannelName}
                          expanded={expanded === category.pannelName}
                          onChange={handleChange(category.pannelName)}
                        >
                          <AccordionSummary
                            expandIcon={<ArrowDownIcon />}
                            aria-controls={`${category.pannelName}bh-content`}
                            id={`${category.pannelName}bh-header`}
                          >
                            <Typography className={classes.heading}>
                              {category.title}
                            </Typography>
                            {/*
                              <Typography className={classes.secondaryHeading}>
                                  Exibir Formulários
                               </Typography>
                            */}
                          </AccordionSummary>

                          <AccordionDetails>
                            <List
                              component="nav"
                              className={classes.pannelsWrapper}
                            >
                              {category.itens.map(item => (
                                <ListItem
                                  key={item.name}
                                  button
                                  component="a"
                                  href={item.url}
                                  rel="noreferrer noopener"
                                  target="_blank"
                                >
                                  <ListItemIcon>
                                    <DescriptionIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={item.name} />
                                  {/* <Divider /> */}
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
